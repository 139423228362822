import axios from 'axios'

export function GetAddress (cep) {
  // Nova variável 'cep' somente com dígitos.
  cep = cep.replace(/\D/g, '')

  // Verifica se campo cep possui valor informado.
  if (cep !== '') {
    // Expressão regular para validar o CEP.
    const validacep = /^[0-9]{8}$/

    // Valida o formato do CEP.
    if (validacep.test(cep)) {
      return new Promise((resolve, reject) => {
        axios.get('https://viacep.com.br/ws/' + cep + '/json/').then(response => {
          if (Object.keys(response).length === 0) {
            resolve({})
          }

          resolve({ logradouro: response.data.logradouro, bairro: response.data.bairro, estado: response.data.uf, cidade: response.data.localidade })
        }).catch(function (error) {
          console.log(error)
          reject(error)
        })
      })
    }
  }
  return {}
}
