<template>
  <div class="container-fluid">
		<div class="card">
			<div class="card-header">
				<div class="row">
					<div class="col">
						<h3>Cadastro de matriz empresarial</h3>
					</div>
				</div>
			</div>
			<form @submit.prevent="salvar">
				<div class="card-body">
					<div class="row">
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="razaosocial">Razão Social</label>
								<input v-model="empresa.razao_social" type="text" class="form-control" name="razaosocial" id="razaosocial"  placeholder="Razão Social">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group"  :class="{ 'form-group--error': $v.empresa.nome_fantasia.$error }">
								<label for="nomefantasia" class="required">Nome Fantasia</label>
								<input v-model="empresa.nome_fantasia" type="text" class="form-control" name="nomefantasia" id="nomefantasia"  placeholder="Nome Fantasia">
								<div class="error-form text-danger" v-if="!$v.empresa.nome_fantasia.required">Campo obrigatório</div>
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="responsavel">Responsável</label>
								<input v-model="empresa.responsavel" type="text" class="form-control" name="responsavel" id="responsavel"  placeholder="Nome do responsável">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="nit">NIT</label>
								<input v-model="empresa.nit" type="text" class="form-control" name="nit" id="nit"  v-mask="'###.#####.##-#'" placeholder="999.99999.99-9">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group" :class="{ 'form-group--error': $v.cnpj.$error }">
								<label for="cnpj" class="required">CNPJ</label>
								<input v-model="cnpj" type="text" class="form-control" name="cnpj" id="cnpj"  placeholder="99.999.999/9999-99" v-mask="'##.###.###/####-##'">
								<div class="error-form text-danger" v-if="!$v.cnpj.required">Campo obrigatório</div>
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="inscricaoestadual">Inscrição Estadual</label>
								<input v-model="empresa.inscricao_estadual" type="text" class="form-control" name="inscricaoestadual" id="inscricaoestadual"  placeholder="Inscrição Estadual">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="graurisco">Grau de Risco</label>
								<input v-model="empresa.grau_risco" type="text" class="form-control" name="graurisco" id="graurisco"  placeholder="Grau de Risco">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="areaatividade">Área de Atividade</label>
								<input v-model="empresa.area_atividade" type="text" class="form-control" name="areaatividade" id="areaatividade"  placeholder="Área de Atividade">
							</div>
						</div>
					</div>
					<hr>
					<div class="row">
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="telefone">Telefone</label>
								<input v-model="empresa.telefone" type="tel" class="form-control" name="telefone" id="telefone" placeholder="(99) 99999-9999"
								v-mask="['(##) ####-####', '(##) #####-####']"
                :masked="true">
							</div>
						</div>
					</div>
					<hr>
					<div class="row">
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="cep">CEP</label>
								<input v-model="cep" type="text" class="form-control" name="cep" id="cep" placeholder="99.999-999" v-mask="'#####-###'">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="logradouro">Logradouro</label>
								<input v-model="empresa.logradouro" type="text" class="form-control" name="logradouro" id="logradouro">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="numero">Número</label>
								<input v-model="empresa.numero" type="text" ref="numero" class="form-control" name="numero" id="numero">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="complemento">Complemento</label>
								<input v-model="empresa.complemento" type="text" class="form-control" name="complemento" id="complemento">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="bairro">Bairro</label>
								<input v-model="empresa.bairro" type="text" class="form-control" name="bairro" id="bairro">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="cidade">Cidade</label>
								<input v-model="empresa.cidade" type="text" class="form-control" name="cidade" id="cidade">
							</div>
						</div>
						<div class="col-md-4 col-sm-12">
							<div class="form-group">
								<label for="estado">Estado</label>
								<input v-model="empresa.estado" type="text" class="form-control" name="estado" id="estado" v-mask="'AA'">
							</div>
						</div>
					</div>
					<hr>
					<div class="row">
						<div class="col-md-4 col-xs-12">
							<div class="form-group">
								<label for="estado">Logo</label>
								<input type="text" v-model="selectedFile.name" class="form-control " name="logo" id="logo">
								<input type="file" class="form-cntrol" 
								@change="onFileSelected" 
								style="display: none;" 
								ref="fileInput">
								<button type="button" class="btn btn-primary mt-3 btn-block" 
								@click="$refs.fileInput.click()">Selecionar Logo</button>
							</div>
						</div>
						<div class="col-md-8 col-xs-12">
							<img v-if="imagePreview" :src="imagePreview" style="max-height:300px;" class="img-fluid" alt="Preview da logo">
						</div>
					</div>
					<div class="row">
						<div class="col-md-12 mt-5">
							<button type="button" class="btn btn-danger float-right mx-3" @click="goBack">CANCELAR</button>
							<button type="submit" class="btn btn-primary float-right">SALVAR</button>
						</div>
					</div>
				</div>
			</form>
		</div>
  </div>
</template>

<script>
import empresaRepository from './../../services/api/empresaRepository'
import {GetAddress} from './../../services/cep.service'
import { validarCNPJ } from './../../services/cnpj.service'
import { required } from 'vuelidate/lib/validators'
export default {
	validations: {
    empresa: {
			nome_fantasia: {
				required
			},
			cnpj: {required}
		},
		cnpj: {required}
  },
	data () {
		return {
			selectedFile: {
				name: ''
			},
			imagePreview: null,
			empresa: {
				nome_fantasia: null,
				cnpj: null
			},
			cnpj: null,
			cep: null
		}
	},
	watch: {
    cep: {
      handler () {
        if (this.cep && this.cep !== null && this.cep.length == 9) {
					this.buscaCep()
				}
      }
		},
		cnpj: {
      handler () {
        if (this.cnpj && this.cnpj !== null && this.cnpj.length == 18) {
					this.verificaCNPJ()
				}
      }
    }
	},
	created () {
		if (this.$route.query.id) {
			this.buscaEmpresa(this.$route.query.id)
		}
	},
	methods: {
		verificaCNPJ() {
			let cnpj = this.cnpj
			cnpj = cnpj.replace(/\./g,'').replace('-','')
			let check = validarCNPJ(cnpj)
			if (!check) {
				this.$swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'CNPJ inválido!',
				}).then ( () => {
					this.cnpj = null
				})
			}
			return check
		},
		buscaEmpresa (idEmpresa) {
			empresaRepository.buscaEmpresa(idEmpresa).then(response => {
				this.empresa = response.data['data']
				this.cep = this.empresa.cep
				this.cnpj = this.empresa.cnpj
				if(this.empresa.logo) {
					this.imagePreview = `http://gestorvida-dev/${this.empresa.logo}`
				}
			})
		},
		buscaCep: function () {
			GetAddress(this.cep).then(endereco => {
				this.empresa.cep = this.cep
				this.empresa.logradouro = endereco.logradouro
				this.empresa.bairro = endereco.bairro
				this.empresa.cidade = endereco.cidade
				this.empresa.estado = endereco.estado
				this.$refs.numero.focus()
				// resolve, mas nao deveria precisar
				this.$forceUpdate()
			})
		},
		onFileSelected (event) {
			this.selectedFile = event.target.files[0]
			if (this.getAttachmentSize() > 1) {
				alert('Selecione uma imagem menor que 1mb!')
				this.removeAttachment()
			} else {
				this.imagePreview = URL.createObjectURL(this.selectedFile)
			}
		},
		getAttachmentSize () {
			let upload_size = 0 // Reset to beginningƒ
			if (this.selectedFile.name !== '') {
				upload_size = parseInt(this.selectedFile.size) / 1024 / 1024
			}
			return Number((upload_size).toFixed(1))
		},
		removeAttachment () {
      this.selectedFile = {
				name: ''
			}
			this.imagePreview = null
		},
		goBack() {
      window.history.length > 1 ? this.$router.go(-1) : this.$router.push({ name: 'Empresas' })
    },
		salvar () {
			this.empresa.cnpj = this.cnpj
			this.$v.$touch()
			if (this.$v.$invalid) {
        this.$swal.fire({
					icon: 'error',
					title: 'Oops...',
					text: 'Preencha o formulário corretamente!',
				})
      } else {
				const fd = new FormData()
				if (this.selectedFile.name !== '') {
					fd.append('imagem', this.selectedFile, this.selectedFile.name)
				}
				// REMOVE AS PROPRIEDADES NULAS PARA NAO COMPLICAR O FORMDATA
				for(var key in this.empresa) {
					if(this.empresa[key] === null) {
						console.log(key + " is blank. Deleting it");
						delete this.empresa[key]
					}
				}
				fd.append('dados', JSON.stringify(this.empresa))
				if (this.empresa.id) {
					empresaRepository.atualizarEmpresa(this.empresa.id, fd).then(response => {
						if (response.data['success']) {
							this.$swal({
								icon: 'success',
								text: 'Cadastro atualizado com sucesso!'
							}).then( () => {
								this.goBack()
							})
						}
					})
				} else {
					empresaRepository.salvarEmpresa(fd).then(response => {
						if (response.data['success']) {
							this.$swal({
								icon: 'success',
								text: 'Empresa cadastrada com sucesso!'
							}).then( () => {
								// this.goBack()
								this.$router.go()
							})
						} else {
							this.$swal({
								icon: 'error',
								text: 'Não foi possível realizar o cadastro!'
							})
						}
					})
				}
			}
		}
	}
}
</script>

<style>

</style>