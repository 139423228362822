import Repository from './repository'

const resource = '/v1/empresas'

export default {
  buscaEmpresa (idEmpresa) {
    return Repository.get(`${resource}/${idEmpresa}`)
  },
  listarEmpresas () {
    return Repository.get(`${resource}`)
  },
  salvarEmpresa (empresa) {
    return Repository.post(`${resource}`, empresa, { headers: { 'Content-Type': 'multipart/form-data' } })
  },
  atualizarEmpresa (idEmpresa, empresa) {
    return Repository.post(`${resource}/${idEmpresa}`, empresa, { headers: { 'Content-Type': 'multipart/form-data' } })
  },
  delete (id) {
    return Repository.delete(`${resource}/${id}`)
  },
  buscaEmpresaUnidade (idEmpresa) {
    return Repository.get(`${resource}/unidade/${idEmpresa}`)
  },
  listarEmpresaUnidades (idEmpresa) {
    return Repository.get(`${resource}/unidades/${idEmpresa}`)
  },
  listarUnidades () {
    return Repository.get(`${resource}/unidades`)
  },
  salvarEmpresaUnidade (empresa) {
    return Repository.post(`${resource}/unidade`, empresa)
  },
  atualizarEmpresaUnidade (idEmpresa, empresa) {
    return Repository.put(`${resource}/unidade/${idEmpresa}`, empresa)
  },
  deleteUnidade (id) {
    return Repository.delete(`${resource}/unidade/${id}`)
  }
}
